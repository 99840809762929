exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-products-js": () => import("./../../../src/pages/our-products.js" /* webpackChunkName: "component---src-pages-our-products-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-promo-clothing-js": () => import("./../../../src/pages/promo-clothing.js" /* webpackChunkName: "component---src-pages-promo-clothing-js" */),
  "component---src-pages-promo-items-js": () => import("./../../../src/pages/promo-items.js" /* webpackChunkName: "component---src-pages-promo-items-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-uniformcity-js": () => import("./../../../src/pages/uniformcity.js" /* webpackChunkName: "component---src-pages-uniformcity-js" */),
  "component---src-pages-uniforms-js": () => import("./../../../src/pages/uniforms.js" /* webpackChunkName: "component---src-pages-uniforms-js" */),
  "component---src-pages-workwear-js": () => import("./../../../src/pages/workwear.js" /* webpackChunkName: "component---src-pages-workwear-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blogPage.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */)
}

